.App {
  text-align: center;
}

.App-page {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: gray;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url(./fonts/Raleway/static/Raleway-Light.ttf) format('truetype');
}
